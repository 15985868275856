// 质检记录
<template>
  <div class="menu">
    <!-- 1.头部搜索 -->
    <el-row>
      <el-col :span="12">
        <div class="title-name"> 
          数据列表
        </div>
      </el-col>
      <el-col :span="12">
        <div class="title-buttom">
          <div class="ml-10">
            <el-button @click="exportTableAll" class="button-el" size="medium">
              <icon-font type="icon-daochushuju" class="button-icon-font" />
              导出数据
            </el-button>
          </div>
          <div class="ml-10">
            <el-button icon="el-icon-search" class="button-el" size="medium" @click="theQueryDialog=true">查询</el-button>
          </div>
          <div class="ml-10">
            <el-tooltip content="刷新列表" placement="top" effect="light">
              <div class="font-refresh-out flex-nowrap-center-center" @click="reset(1)">
                <icon-font v-if="loading==false" type="icon-shuaxin" class="font-refresh" />
                <i v-else class="el-icon-loading loding-color"></i>
              </div>
            </el-tooltip>
          </div>
          <div class="ml-10">
            <el-tooltip content="返回上一页" placement="top" effect="light">
              <div class="font-refresh-out flex-nowrap-center-center" @click="goBack">
                <icon-font type="icon-fanhuishangyiye" class="font-refresh" />
              </div>
            </el-tooltip>
          </div>
        </div>
      </el-col>
    </el-row>
    <!-- 2.主体表格 -->
    <el-row>
      <el-col :span="24">
        <div>
          <el-table class="eltable" v-loading="loading" ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%" :header-cell-style="{background:'#e0e5ff',color:'#24252F'}" row-key="id" size="mini" :row-style="{height:'50px'}" :cell-style="{padding:'0px'}" default-expand-all :tree-props="{children: 'children', hasChildren: 'hasChildren'}" @selection-change="handleSelectionChange">
            <!-- <el-table-column
                        type="selection"
                        width="55">
                        </el-table-column> -->
            <el-table-column label="号码">
              <template #default="scope">{{ scope.row.number == null|| scope.row.number == ''?'-': scope.row.number }}</template>
            </el-table-column>
            <el-table-column label="服务商">
              <template #default="scope">{{scope.row.amountId?amountMap[scope.row.amountId]:'--' }}</template>
            </el-table-column>
            <el-table-column label="价格">
              <template #default="scope">￥{{ scope.row.price == null|| scope.row.price == ''?'-': scope.row.price }}</template>
            </el-table-column>
            <el-table-column label="创建时间">
              <template #default="scope">{{ scope.row.createTime == null|| scope.row.createTime == ''?'-': scope.row.createTime }}</template>
            </el-table-column>
          </el-table>
        </div>
      </el-col>
    </el-row>
    <!-- 3.分页器 -->
    <div class="pager">
      <div>
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage" background :page-sizes="[10, 20, 30, 40]" :page-size="searchFrom.pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total">
        </el-pagination>
      </div>
    </div>
    <!-- 查询模态框 -->
    <div class="screening">
      <el-dialog title="查询" v-model="theQueryDialog" :close-on-click-modal="false" :destroy-on-close="true" width="600px">
        <el-form label-position="left" label-width="100px" size="small" :model="searchFrom" ref="upform">
            <el-form-item label="服务商：" style="width: 100%">
                  <el-select v-model="searchFrom.amountId" filterable placeholder="请选择" style="width: 100%" clearable>
                    <el-option v-for="(item, index) in amount_id" :key="index" :label="item.name" :value="item.id">
                    </el-option>
                  </el-select>
                </el-form-item>
          <el-form-item label="号码" style="width: 100%" >
            <el-input v-model="searchFrom.number" class="input-searchFrom-width" placeholder="请输入需要查询的手机号" size="mini"></el-input>
          </el-form-item>
          <el-form-item label="创建时间" style="width: 100%" >
            <el-date-picker v-model="valueTime" type="datetimerange" start-placeholder="开始日期" end-placeholder="结束日期" clearable value-format="YYYY-MM-DD HH:mm:ss" size="mini" :default-time="defaultTime">
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <div class="flex-nowrap-flex-end">
              <el-button class="cancel" plain @click="reset" round>
                重置
              </el-button>
              <el-button class="confirmAdd" @click="onSubmit" round>
                查询
              </el-button>
            </div>
          </el-form-item>
        </el-form>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import tradeDetails from '@/api/web/tradeDetails'
import { IconFont } from '@/utils/iconfont'
import { ElMessageBox, ElMessage } from 'element-plus'
import amountts from '@/api/open/privacy/amount'
export default {
  components: {
    IconFont,
  },
  props: {
    id: {
      type: Number,
      default: '',
    }
  },
  data() {
    return {
      tableHeight: '',
      searchFrom: {
        page: 1,
        pageSize: 10,
        detailId: '',
        amountId:''
      },
      total: 0,
      currentPage: 1, //分页
      multipleSelection: [], //选中集合
      valueTime: [], //选中时间段
      defaultTime: [
        new Date(2000, 1, 1, 0, 0, 0),
        new Date(2000, 2, 1, 23, 59, 59),
      ], //默认时间 '00:00:00', '23:59:59'
      tableData: [], //菜单表
      // loding 状态启用
      loading: true,
      hitType: '',
      theQueryDialog: false,
      fatherCurrentPage: 1,
      amount_id:[],
      amountMap: {},
    }
  },
  created() {
    this.tableHeight = this.$store.state.tableHeight
  },
  watch: {},
  mounted() {
    // this.fatherCurrentPage =Number(this.$route.query.currentPage) 
    this.searchFrom.detailId =this.id
    this.getDataList(true)
    this.getAmountList()
  },
  methods: {
    // 获取数据列表
    getAmountList() {
      amountts.getAmountList({}).then((res) => {
        this.amount_id = res.data
        res.data.forEach((item, i) => {
          this.amountMap[item.id] = item.name
        })
      })
    },
    // 导出用户列表
    exportTableAll() {
      ElMessageBox.confirm('将导出全部数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          tradeDetails
            .exportList({ detailId: this.id })
            .then((res) => {
              if (res.code !== 200) {
                return ElMessage({
                  type: 'error',
                  message: res.message,
                })
              }
              ElMessage({
                type: 'success',
                message: '列表导出导出任务已建立,稍后请在消息模块下载',
              })
            })
        })
        .catch(() => {
          ElMessage({
            type: 'info',
            message: '已取消',
          })
        })
    },
    // 查询
    onSubmit() {
      this.getDataList(true)
      this.theQueryDialog = false
    },
    // 返回上一页
    goBack() {
      this.$store.commit('SET_showT', false);
      // this.$router.go(-1)
      // this.$router.push({
      //   path: '/cost/trade',
      //   query: {
      //     currentPage: Number(this.fatherCurrentPage),
      //   },
      // })
    },
    //
    changeValues(val) {
      this.searchFrom.durationBegin = val[0]
      this.searchFrom.durationEnd = val[1]
    },
    // 重置
    reset(kind) {
      this.searchFrom = {
        page: this.currentPage,
        pageSize: 10,
      }
      this.searchFrom.detailId = this.id
      this.valueTime = []
      if (kind == 1) {
        this.getDataList(true)
      }
    },
    // 获取数据列表
    getDataList(type) {
      this.loading = type
      if (this.valueTime != null) {
        this.searchFrom.createTimeBegin = this.valueTime[0]
        this.searchFrom.createTimeEnd = this.valueTime[1]
      } else {
        this.searchFrom.createTimeBegin = ''
        this.searchFrom.createTimeEnd = ''
      }
      tradeDetails
        .tradeDetailsList({
          ...this.searchFrom,
        })
        .then((res) => {
          this.tableData = res.data.records
          this.total = res.data.total
          this.loading = false
        })
    },
    // 分页
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
      this.searchFrom.pageSize = val
      this.getDataList(true)
    },
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`);
      this.searchFrom.page = val
      this.currentPage = val
      this.getDataList(true)
    },
    // 多选
    handleSelectionChange(val) {
      this.multipleSelection = val
    },
  },
}
</script>

<style lang="scss" scoped>
.menu {
  height: 100%;
  overflow-y: overlay;
  padding-right: 1.125rem;
  .title-name {
    font-size: 0.9375rem;
    font-weight: 600;
    color: #303133;
  }
  .top-first {
    .search-box {
      width: 18.75rem;
    }
  }
}
.screening {
  margin-bottom: 0.9375rem;
}
.el-row {
  margin-bottom: 0.9375rem;
  &:last-child {
    margin-bottom: 0;
  }
}

.title-buttom {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: center;
}
.editor {
  color: #0170ed;
  background: #e3f0ff;
}
.center-body {
  margin: 0 1.875rem;
}
.deletetable {
  color: #ff845b;
  background: #ffe4db;
}
.deletetable-other {
  margin-right: 1.25rem;
}
.confirmAdd {
  background: #637dff;
  color: #fff;
}
.cancel {
  color: #637dff;
  border: #637dff solid 1px;
}
.screening-out {
  margin: 0 0.9375rem;
}
.out-icon:hover {
  background: #e3f0ff;
  color: #0170ed;
}
.pager {
  display: flex;
  justify-content: flex-end;
}
.pass {
  color: #67c23a;
}
.unpass {
  color: #f56c6c;
}
.priceColor {
  color: #409eff;
}
.talks {
  cursor: pointer;
}
.record {
  height: 5rem;
}
.dialogue {
  overflow-y: overlay;
  height: 25rem;
  padding: 0.425rem 1.5rem;
  background: #f5f5f5;
}
.recordSon {
  width: 100%;
}
.item-text {
  display: inline-block;
  padding: 0.375rem;
  border-radius: 0.375rem;
  color: #000;
}
.item-left {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.item-right {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: center;
}
.title-left {
  text-align: left;
}
.title-right {
  text-align: right;
}
.title-item {
  padding: 0.375rem;
}
.talkcolor {
  font-size: 1rem;
  color: #fff;
}
.talkcolorOther {
  font-size: 1rem;
  color: red;
}
// .unRead{

// }
.Read {
  background: #abadbb;
}
</style>

<style lang="scss">
.menu .el-collapse-item__content {
  padding-bottom: 0 !important;
}
.eltable {
  overflow-y: overlay;
}
</style>